import React from 'react'
import classNames from 'classnames'

interface IHeaderProps {
  headline: string
  copy?: string
  image?: any
  callToAction?: any
  caption?: boolean
  alignRight?: boolean
  alignCenter?: boolean
}

const defaultProps: Partial<IHeaderProps> = {
  alignRight: false,
}

const Header = (props: IHeaderProps) => (
  <header
    className={classNames('header', {
      'header--with-image': !!props.image,
      'header--right': props.alignRight || !!props.image,
      'header--center': props.alignCenter,
    })}
  >
    <div className="header__container">
      {props.image && (
        <div className="header__image">
          <img alt={props.headline} src={props.image} />
        </div>
      )}
      <div className="header__text">
        <h1>{props.headline}</h1>
        {props.copy && <p>{props.copy}</p>}
        {!!props.callToAction && props.callToAction}
      </div>

      {props.caption && (
        <div className="header__text__caption">
          David C. Templeton, RFC
          <br />
          Founder & President
          <br />
          Adage Financial Consultants, Inc.
        </div>
      )}
    </div>
  </header>
)

Header.defaultProps = defaultProps

export default Header
