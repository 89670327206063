import React from 'react'
import classNames from 'classnames'

const Columns = ({ children, background }) => (
  <section
    className={classNames('marketing', {
      'marketing--light': !!background,
    })}
  >
    <div className="marketing__container">{children}</div>
  </section>
)

export default Columns
