import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'

import Header from '../../components/header'
import Columns from '../../components/columns'
import Sidebar from '../../components/sidebar'
import Quote from '../../components/quote'
import FinancialPlanningNav from '../../components/financial-planning-nav'

const image = require('../../images/samples/retirement2.png')

const InvestmentServicesPage = () => (
  <Layout>
    <Helmet
      title={'Safe Money'}
      meta={[
        {
          name: 'description',
          content:
            // tslint:disable-next-line:max-line-length
            'Integrating safe money strategies into your financial portfolio can protect you from market loss during a downturn, while giving you the opportunity to realize stock market gains during times of growth.',
        },
        {
          name: 'keywords',
          content:
            // tslint:disable-next-line:max-line-length
            'Adage Financial Consultants, David Templeton, Little Rock Arkansas, Financial Advisor, Financial Planner, 401k, Safe Money, Annuity',
        },
      ]}
    />
    <Header headline={'Safe Money'} image={image} />
    <Columns background>
      <Sidebar>
        <FinancialPlanningNav />
      </Sidebar>
      <div className="marketing__column">
        <Quote text="Safety and Protection for Volatile Times" />
        <p>
          Protecting your hard-earned assets in one of the most volatile eras in
          generations can be easier than you think. There may not be a more
          important time than now to safeguard all that you’ve worked so hard to
          achieve.
        </p>
        <p>
          Integrating safe money strategies into your financial portfolio can
          protect you from market loss during a downturn, while giving you the
          opportunity to realize stock market gains during times of growth. This
          is made possible because your money is not actually being invested
          directly in the stock market, but rather linked to the growth of the
          market, while also providing underlying guarantees. These strategies
          can provide the best of both worlds – security and results. Adage
          Financial Consultant’s professionals have access to some of the most
          competitive, guaranteed, and safe strategies available to the public
          today.
        </p>
        <p>
          The difference is, with our fiduciary responsibility, we want for your
          plan to best suit YOUR needs, not ours. The fact is, banks, financial
          insurance companies, and brokerage firms all want your money, but what
          sets us apart is that we are on your side of the table.
        </p>
        <p>
          Financial products and services, like everything else, are
          continuously evolving. The features and benefits of the services
          available today, compared to even a few years ago are as different as
          the comparison between apples and oranges. One of the biggest
          innovations of the modern financial world is the technology available.
          With the ability to tie your hard earned money to a portion of market
          growth, without the risk of market downturns, we are able to help you
          ensure a steady and predictable growth that will provide you with a
          steady and predictable income that you can’t outlive.
        </p>
      </div>
    </Columns>
  </Layout>
)

export default InvestmentServicesPage
