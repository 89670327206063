import React from 'react'
import { Link } from 'gatsby'

const FinancialPlanningNav = () => (
  <nav>
    <ul>
      <li>
        <Link to="/financial-planning" activeClassName="sidebar--active">
          Financial Planning
        </Link>
      </li>
      <li>
        <a
          target="_blank"
          href="https://www.401kmaneuver.com/david-templeton-lp"
        >
          401k Maneuver Program
        </a>
      </li>
      <li>
        <Link
          activeClassName="sidebar--active"
          to="/financial-planning/investment-services"
        >
          Investment Services
        </Link>
      </li>
      <li>
        <Link
          activeClassName="sidebar--active"
          to="/financial-planning/retirement-planning"
        >
          Retirement Planning
        </Link>
      </li>
      <li>
        <Link
          activeClassName="sidebar--active"
          to="/financial-planning/risk-protection"
        >
          Risk Protection
        </Link>
      </li>
      <li>
        <Link
          activeClassName="sidebar--active"
          to="/financial-planning/safe-money"
        >
          Safe Money
        </Link>
      </li>
    </ul>
    <div className="sidebar__copy">
      <p>
        If you have questions about your financial future, we're here to help
        and welcome the opportunity to work with you to craft a financial plan
        that is as unique as you are.
      </p>
      <Link to={'/contact'}>
        <button>Contact Us</button>
      </Link>
    </div>
  </nav>
)

export default FinancialPlanningNav
